import { Component, OnInit } from "@angular/core";
import { VehiclesService } from "./services/vehicles.service";
import { VehicleModel } from "./pages/maintenance/interfaces/maintenance.interfaces";

@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styles: [],
})
export class DashboardComponent implements OnInit {
	viewDashboard: boolean = false;
	navbarCollapsed!: boolean;
	constructor(private vs: VehiclesService) {}
	ngOnInit(): void {
		this.getFilters();
	}
	getFilters(): void {
		this.viewDashboard = false;

		this.vs.getVehicles().subscribe((res: VehicleModel[]) => {
			this.vs.vehicles = res;
			this.viewDashboard = true;
		});
	}
}
