import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as moment from "moment-timezone";

@Component({
	selector: "app-datetime-complete",
	templateUrl: "./datetime-complete.component.html",
	styles: [],
})
export class DatetimeCompleteComponent implements OnInit {
	FORMAT_DATE: string = "YYYY-MM-DD";
	@Output() emitValue: EventEmitter<string> = new EventEmitter();
	// Decidir si es start o end
	@Input() end: boolean = false;
	// Formulario
	form!: UntypedFormGroup;
	constructor(private fb: UntypedFormBuilder) {}
	ngOnInit(): void {
		this.createForm();
	}
	createForm(): void {
		this.form = this.fb.group({
			date: [this.end ? moment() : moment().subtract(1, "day"), Validators.required],
			time: [this.end ? "23:59" : "00:00", Validators.required],
		});
		this.finish();
		this.form.valueChanges.subscribe(() => {
			this.finish();
		});
	}
	finish(): void {
		const { date, time } = this.form.value;
		const datetime: string = `${date.format(this.FORMAT_DATE)} ${time}:${this.end ? "59" : "00"}`;
		this.emitValue.emit(datetime);
	}
}
