<aside class="nav-save" [class]="{'nav-save--collapsed': navbarCollapsed}">
  <button type="button" class="nav-save-button" (click)="toggleCollapse()"><i class="fas fa-bars"></i></button>
  <ul class="list-unstyled nav-save-list">
    <li *ngFor="let item of sidebar">
      <a [routerLink]="[item.routerlink]" routerLinkActive="nav-save--selected" class="hover-animation"
        (click)="redirectLog(item)">
        <i class="d-block" [class]="item.icon"></i>
        <p *ngIf="!navbarCollapsed">{{ item.text }}</p>
      </a>
    </li>
    <li>
      <a (click)="logout()"><i class="fas fa-sign-out-alt"></i>
        <p *ngIf="!navbarCollapsed">Salir</p>
      </a>
    </li>
  </ul>

  <mat-form-field appearance="legacy" [hidden]="navbarCollapsed" class="mx-2">
    <mat-label>Timezone</mat-label>
    <mat-select [(ngModel)]="tzSelected" name="option" (ngModelChange)="changeTimezone()">
      <mat-option *ngFor="let tz of timezones" [value]="tz.name">
        {{ tz.text }}
      </mat-option>
    </mat-select>
  </mat-form-field>

</aside>