import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { ITimezones, timezones } from '../data/timezones.data';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  public timezoneActual!: string;
  constructor() {
    this.getZoneActual();
  }
  private getZoneActual(): void {
    this.setZoneActual(
      localStorage.getItem('timezone-actual') ||
        moment.tz.guess() ||
        'America/Buenos_Aires'
    );
  }
  public setZoneActual(name: string): void {
    this.timezoneActual = name;
    localStorage.setItem('timezone-actual', this.timezoneActual);
  }
  public transformDatetime(datetime: string): string {
    return moment(datetime)
      .utc(true)
      .tz(this.timezoneActual)
      .format('YYYY-MM-DD HH:mm:ss');
  }
  get allTimezones(): ITimezones[] {
    return timezones;
  }
}
