import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-input-autocomplete-modular',
  templateUrl: './input-autocomplete-modular.component.html',
  styles: [],
})
export class InputAutocompleteModularComponent implements OnInit, OnChanges {
  @Input() name!: string;
  @Input() arrInput: any[] = [];
  @Input() keyInput!: string;
  @Output() valueEmit: EventEmitter<string> = new EventEmitter();

  myValueCtrl = new UntypedFormControl();
  options!: string[];
  filteredOptions!: Observable<string[]>;

  constructor() {}

  ngOnChanges(): void {}
  ngOnInit(): void {
    this._filterOnInit();
  }
  private _filterOnInit(): void {
    if (this.keyInput) {
      this.options = this.arrInput
        .filter((val) => val[this.keyInput])
        .map((val) => val[this.keyInput]);
      this.filteredOptions = this.myValueCtrl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    } else {
      this.options = this.arrInput.filter((val) => val).map((val) => val);
      this.filteredOptions = this.myValueCtrl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const valueEmit =
      this.myValueCtrl.value == null || this.myValueCtrl.value == undefined
        ? ''
        : this.myValueCtrl.value;

    this.valueEmit.emit(valueEmit);
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
