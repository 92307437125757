import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const { base_url } = environment;

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  private url = `${base_url}adminService.php`;
  constructor(private http: HttpClient) {}

  sendLog(log: Log) {
    this.http
      .post(this.url, { ...log, req: JSON.stringify(log.req), name: 'SET_LOG' })
      .subscribe((res) => {});
  }
}

export interface Log {
  page: Page;
  log: LogName;
  req: Object;
}

export type Page =
  | 'login'
  | 'config'
  | 'maintenance'
  | 'report'
  | 'admin'
  | 'technical'
  | 'users'
  | 'installations'
  | 'tracking'
  | 'none';

export type LogName =
  // ? Navigation
  | 'NAV_login'
  | 'NAV_config'
  | 'NAV_maintenance'
  | 'NAV_report'
  | 'NAV_admin'
  | 'NAV_technical'
  | 'NAV_users'
  | 'NAV_installations'
  | 'NAV_tracking'
  | 'NAV_none'
  | 'LOGIN'
  | 'LOGOUT'
  | 'CHANGE_TIMEZONE'
  // ? MAINTENANCE
  | 'MAINTENANCE_FILTERS'
  | 'MAINTENANCE_DOWNLOAD_EXCEL'
  | 'MAINTENANCE_VIEW_IMGS'
  | 'MAINTENANCE_CALIBRATE'
  // ? CONFIG
  | 'CONFIG_FILTERS'
  | 'CONFIG_CHANGE_PLATE'
  | 'CONFIG_CHANGE_VOLUME'
  | 'CONFIG_UPDATE'
  | 'CONFIG_SET_SPEED'
  | 'CONFIG_CHANGE_LNG'
  | 'CONFIG_CLEAN_EVENTS'
  | 'CONFIG_CREATE_SPECIAL_FOLDER'
  | 'CONFIG_EXEC_OTHER_SCRIPT'
  | 'CONFIG_GET_BLACKBOX'
  // ? REPORT
  | 'REPORT_GET_REPORT'
  // ? ADMIN
  | 'ADMIN_NAV_USERS'
  | 'ADMIN_NAV_FLEETS_GROUPS'
  | 'ADMIN_NAV_VEHICLES'
  | 'ADMIN_USERS_ASSIGN_FLEET'
  | 'ADMIN_USERS_ASSIGN_GROUP'
  | 'ADMIN_USERS_UNASSIGN_GROUP'
  | 'ADMIN_FLEETS_GROUPS_CREATE_FLEET'
  | 'ADMIN_FLEETS_GROUPS_CREATE_GROUP'
  | 'ADMIN_FLEETS_GROUPS_ASSIGN_GROUP'
  | 'ADMIN_FLEETS_GROUPS_UNASSIGN_GROUP'
  | 'ADMIN_VEHICLES_FILTERS'
  | 'ADMIN_VEHICLES_ASSIGN_GROUP'
  | 'ADMIN_VEHICLES_UNASSIGN_GROUP'
  | 'ADMIN_VEHICLES_DELETE_VEHICLE'
  | 'ADMIN_VEHICLES_OPERATION'
  // ? TECHNICAL_SERVICE
  | 'TECHNICAL_SERVICE_CLONES'
  | 'TECHNICAL_SERVICE_GET_INFO'
  // ? INSTALLATIONS
  | 'INSTALLATIONS_FILTERS'
  | 'INSTALLATIONS_DOWNLOAD_EXCEL'
  // ? TRACKING
  | 'TRACKING_GET_TRACKING'
  | 'TRACKING_DOWNLOAD_EXCEL_DATA';
