import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { VehicleModel } from "../pages/maintenance/interfaces/maintenance.interfaces";
import { environment } from "src/environments/environment";
import { IVehicleBody } from "./interfaces/vehicle.interface";
import { UpdateVehicle } from "../pages/maintenance/components/update-vehicle/update-vehicle.interface";
import { VersionFw } from "../pages/config/components/update-unit/update-unit.component";
import { PCResponse } from "../pages/report/components/report-pc/report-pc.component";
import { ReportNotificationResponse } from "../pages/report/components/report-notification/report-notification.component";
const { base_url } = environment;

@Injectable({
	providedIn: "root",
})
export class VehiclesService {
	private url = `${base_url}vehiclesService.php`;
	public vehicles!: VehicleModel[];

	constructor(private http: HttpClient) {}

	getVehicles(): Observable<VehicleModel[]> {
		const body: IVehicleBody = { name: "GET_NEW_VEHICLES" };
		return this.http.post<VehicleModel[]>(this.url, body);
	}
	getReport(body: IVehicleBody): Observable<any> {
		return this.http.post(this.url, body);
	}
	getGroups(): Observable<any> {
		return this.http.post(this.url, {
			name: "GET_GROUPS",
		});
	}
	// Configs
	getConfigs(): Observable<any> {
		const body = { name: "GET_CONFIGS" };
		return this.http.post(this.url, body);
	}
	getConfig(plate: string): Observable<any> {
		const body = { name: "GET_CONFIGS", plate };
		return this.http.post(this.url, body);
	}
	downloadConfig(): Observable<any> {
		return this.http.post(this.url, {
			name: "DOWNLOAD_CONFIGS",
		});
	}
	updateVehicle(body: UpdateVehicle): Observable<any> {
		return this.http.post(this.url, {
			name: "UPDATE_NEW_VEHICLES",
			payload: body,
		});
	}
	getVersions() {
		return this.http.post<VersionFw[]>(this.url, {
			name: "GET_VERSION",
		});
	}
	getReportNotifications(body: { dateStart: string; dateEnd: string; serialNumber: string; plate: string }): Observable<any> {
		return this.http.post<ReportNotificationResponse>(this.url, {
			name: "GET_NOTIFICACIONES",
			payload: body,
		});
	}
	getPc(body: { serialNumber: string; plate: string }): Observable<PCResponse> {
		return this.http.post<PCResponse>(this.url, {
			name: "GET_PC",
			payload: body,
		});
	}
}
