<form class="form-savetrack container-fluid p-0" [formGroup]="form" autocomplete="nope">
  <div class="row">
    <div class="col-6">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>Fecha de {{ end?'Fin':'Inicio' }}</mat-label>
        <input matInput [matDatepicker]="date" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>Hora de {{ end?'Fin':'Inicio' }}</mat-label>
        <input matInput [ngxTimepicker]="time" formControlName="time" [format]="24">
        <ngx-material-timepicker #time></ngx-material-timepicker>
      </mat-form-field>
    </div>
  </div>
</form>
