import { NgModule } from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule } from "@angular/material/card";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatRippleModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatChipsModule } from "@angular/material/chips";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatStepperModule } from "@angular/material/stepper";
import { ClipboardModule } from "@angular/cdk/clipboard";

@NgModule({
	declarations: [],
	imports: [
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		MatProgressBarModule,
		MatCardModule,
		MatSnackBarModule,
		MatDialogModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatMomentDateModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatSortModule,
		MatTabsModule,
		MatSelectModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatExpansionModule,
		MatListModule,
		MatTableModule,
		MatButtonToggleModule,
		MatMenuModule,
		MatRippleModule,
		MatTooltipModule,
		MatChipsModule,
		MatPaginatorModule,
		MatStepperModule,
		ClipboardModule,
	],
	exports: [
		MatToolbarModule,
		MatIconModule,
		MatButtonModule,
		MatProgressBarModule,
		MatCardModule,
		MatSnackBarModule,
		MatDialogModule,
		MatInputModule,
		MatSliderModule,
		MatProgressSpinnerModule,
		MatMomentDateModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatSortModule,
		MatTabsModule,
		MatSelectModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatExpansionModule,
		MatListModule,
		MatTableModule,
		MatButtonToggleModule,
		MatMenuModule,
		MatRippleModule,
		MatTooltipModule,
		MatChipsModule,
		MatPaginatorModule,
		MatStepperModule,
		ClipboardModule,
	],
	providers: [{ provide: MAT_DIALOG_DATA, useValue: [] }],
})
export class AngularMaterialModule {}
