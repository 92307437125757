<mat-form-field class="w-100">
  <mat-label>{{ name }}</mat-label>
  <input type="text" placeholder="Elegir.." aria-label="Number" matInput [formControl]="myValueCtrl"
    [matAutocomplete]="auto" />
  <button *ngIf="myValueCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="myValueCtrl.patchValue('')">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
