import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class NoAuthInterceptor implements HttpInterceptor {
  constructor(private _auth: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqClone = request.clone();
    return next.handle(reqClone).pipe(
      catchError((err: HttpErrorResponse) => {
        this._auth.noAuth(err.status);
        return throwError(err);
      })
    );
  }
}
