import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VehiclesService } from '../../../dashboard/services/vehicles.service';

@Component({
  selector: 'app-fleet-group-selector',
  templateUrl: './fleet-group-selector.component.html',
  styleUrls: ['./fleet-group-selector.component.css'],
})
export class FleetGroupSelectorComponent {
  readonly formControl: FormGroup;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  groupList: (string | null)[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private vehiclesService: VehiclesService
  ) {
    this.formControl = formBuilder.group({
      plate: '',
      fleet: '',
      group: '',
    });
    this.formControl.valueChanges.subscribe((v) => this.valueChange.emit(v));
    this.formControl
      .get('fleet')
      ?.valueChanges.subscribe((v) => this.changeFleetListener(v));
  }

  get fleetList() {
    const fleets = this.vehiclesService.vehicles
      .filter((v) => !!v.fleet && v.fleet.length > 0)
      .map((v) => v.fleet);
    return fleets.filter((el, index) => fleets.indexOf(el) === index).sort();
  }
  changeFleetListener(value: string) {
    this.formControl.patchValue({ group: '' });
    this.setGroups(value);
  }

  setGroups(fleet: string) {
    const groups = this.vehiclesService.vehicles
      .filter((v) => !!v.group && v.group.length > 0 && v.fleet === fleet)
      .map((v) => v.group);
    this.groupList = groups
      .filter((el, index) => groups.indexOf(el) === index)
      .sort();
  }
}
