import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as moment from "moment-timezone";
import { UserDataModel, LoginModel } from "../interfaces/auth.models";
import { environment } from "../../../environments/environment";
const { base_url } = environment;
@Injectable({
	providedIn: "root",
})
export class AuthService {
	userData!: any;
	private URL_SCRIPT = `${base_url}loginT.php`;
	constructor(private http: HttpClient, private router: Router) {
		this.getUserData();
	}
	private getUserData(): void {
		if (localStorage.getItem("USER_DATA")) {
			this.userData = JSON.parse(`${localStorage.getItem("USER_DATA")}`);
		} else {
			this.logout();
		}
	}
	private setUserData(data: UserDataModel): void {
		this.userData = data;
		localStorage.setItem("USER_DATA", JSON.stringify(data));
	}
	login(user: LoginModel): Observable<UserDataModel> {
		return this.http.post(this.URL_SCRIPT, user).pipe(
			map((res: any) => {
				this.router.navigateByUrl("/dash/maintenance");
				this.setUserData(res);
				return res;
			}),
		);
	}
	logout(): void {
		localStorage.removeItem("USER_DATA");
		this.router.navigateByUrl("/login");
	}
	noAuth(status: number): void {
		if (status === 401) {
			this.logout();
		}
	}
	itAuthenticated(): boolean {
		const expira = this.userData?.expire;
		const now = moment().format("YYYY-MM-DD HH:mm:ss");
		if (this.userData === undefined || !localStorage.getItem("USER_DATA")) {
			return false;
		} else {
			if (expira !== undefined && expira < now) {
				return false;
			}
			return true;
		}
	}
}
