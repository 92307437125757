import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-select-a-modular',
  templateUrl: './select-a-modular.component.html',
  styles: [],
})
export class SelectAModularComponent implements OnInit {
  @Input() name: string = 'Unidentified';
  @Input() arr: any[] = [];
  @Input() keyToFilter: string = ''; // Key filtrada si es un father o son
  // Suboptions
  @Input() isSon: boolean = false; // Es un subselect? true : false
  @Input() valueFather: string = ''; // Valor que viene desde otro select
  @Input() keyFather: string = ''; // Key que tiene que coincidir con el valueFather
  @Input() ngDisabled: boolean = false;
  @Input() multiple: boolean = false;
  @Output() valueEmitter: EventEmitter<any> = new EventEmitter(); // Valor que emite este select
  selected: string | undefined = '';
  options: string[] = [];
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setOptions();
  }

  setOptions(): void {
    if (this.isSon) {
      this.selected = undefined;
      this.valueEmitter.emit(this.selected);
      const options = this.filterSubOptions(
        this.arr,
        this.keyFather,
        this.keyToFilter,
        this.valueFather
      )
        .filter((value) => value)
        .filter((el, index, array) => array.indexOf(el) === index);
      this.options = [...options];
    } else {
      const options = this.arr
        .map((value) => value[this.keyToFilter])
        .sort()
        .filter((value) => value)
        .filter((el, index, array) => array.indexOf(el) === index);
      this.options = [...options];
    }
  }

  emit(): void {
    this.valueEmitter.emit(this.selected);
  }
  filterSubOptions(
    arr: any,
    father: string,
    son: string,
    match: string
  ): string[] {
    const result = arr
      .filter((vehicle: any) => vehicle[father] == match)
      .map((vehicle: any) => vehicle[son]);
    return result
      .filter((el: any, index: any) => result.indexOf(el) === index)
      .sort();
  }
}
