import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { SidebarItemModel } from "../../interfaces/shared.interface";
import { AuthService } from "../../../auth/services/auth.service";
import { TimezoneService } from "../../../dashboard/services/timezone.service";
import { ITimezones } from "../../../dashboard/data/timezones.data";
import { LogsService, Page } from "../../services/logs.service";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
	sidebar: SidebarItemModel[] = [
		{
			text: "Mantenimiento",
			routerlink: "/dash/maintenance",
			ngIf: this.authService.userData.capabilities.navbar.maintenance,
			icon: "far fa-list-alt",
		},
		{
			text: "Configuración",
			routerlink: "/dash/config",
			ngIf: this.authService.userData.capabilities.navbar.config,
			icon: "fas fa-wrench",
		},

		{
			text: "Report",
			routerlink: "/dash/report",
			ngIf: this.authService.userData.capabilities.navbar.report,
			icon: "far fa-file-excel",
		},
		{
			text: "Administración",
			routerlink: "/dash/admin",
			ngIf: this.authService.userData.capabilities.navbar.admin,
			icon: "fas fa-key",
		},
		{
			text: "Service Técnico",
			routerlink: "/dash/technical",
			ngIf: this.authService.userData.capabilities.navbar.technical,
			icon: "fas fa-microchip",
		},
		{
			text: "Instalaciones",
			routerlink: "/dash/installations",
			ngIf: this.authService.userData.capabilities.navbar.installations,
			icon: "fas fa-toolbox",
		},
		{
			text: "Usuarios",
			routerlink: "/dash/users",
			ngIf: this.authService.userData.capabilities.navbar.users,
			icon: "fas fa-users-cog",
		},
		{
			text: "Tracking",
			routerlink: "/dash/tracking",
			ngIf: this.authService.userData.capabilities.navbar.tracking,
			icon: "fas fa-map-marked-alt",
		},
		{
			text: "Fleet Status",
			routerlink: "/dash/fleet-status",
			ngIf: true,
			icon: "fas fa-chart-line",
		},
		{
			text: "Chips",
			routerlink: "/dash/chips",
			ngIf: true,
			icon: "fas fa-box-open",
		},
		{
			text: "Stock (próximamente)",
			routerlink: "/dash/stock",
			ngIf: true,
			icon: "fas fa-boxes",
		},
	].filter((i) => i.ngIf);
	navbarCollapsed!: boolean;
	timezones!: ITimezones[];
	tzSelected: string = "";
	@Output() collapsed: EventEmitter<boolean> = new EventEmitter();
	constructor(private authService: AuthService, public _tzs: TimezoneService, private logsService: LogsService) {
		this.timezones = this._tzs.allTimezones;
		this.tzSelected = this._tzs.timezoneActual;
	}

	ngOnInit(): void {
		this.navbarInit();
	}
	navbarInit(): void {
		this.navbarCollapsed = localStorage.getItem("navbarCollapsed") === "true" ? true : false || false;
		localStorage.setItem("navbarCollapsed", JSON.stringify(this.navbarCollapsed));
		this.collapsed.emit(this.navbarCollapsed);
	}
	logout(): void {
		this.logsService.sendLog({
			page: "none",
			log: "LOGOUT",
			req: {},
		});

		this.authService.logout();
	}
	toggleCollapse(): void {
		this.navbarCollapsed = !this.navbarCollapsed;
		localStorage.setItem("navbarCollapsed", JSON.stringify(this.navbarCollapsed));
		this.collapsed.emit(this.navbarCollapsed);
	}
	changeTimezone(): void {
		this._tzs.setZoneActual(this.tzSelected);
		this.logsService.sendLog({
			page: "none",
			log: "CHANGE_TIMEZONE",
			req: this.tzSelected,
		});
	}
	// LOGS
	redirectLog(item: SidebarItemModel) {
		const routePage = item.routerlink.split("/")[item.routerlink.split("/").length - 1] as Page;
		this.logsService.sendLog({
			page: routePage,
			log: `NAV_${routePage}`,
			req: {},
		});
	}
}
