import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { ImgModalComponent } from './components/img-modal/img-modal.component';
import { AngularMaterialModule } from '../angular-material.module';
import { DialogConfirmComponent } from './components/dialog-confirm/dialog-confirm.component';
import { InputAutocompleteModularComponent } from './components/input-autocomplete-modular/input-autocomplete-modular.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectAModularComponent } from './components/select-a-modular/select-a-modular.component';
import { DatetimeCompleteComponent } from './components/datetime-complete/datetime-complete.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { FleetGroupSelectorComponent } from './components/fleet-group-selector/fleet-group-selector.component';

@NgModule({
  declarations: [
    NavbarComponent,
    ImgModalComponent,
    DialogConfirmComponent,
    InputAutocompleteModularComponent,
    SelectAModularComponent,
    DatetimeCompleteComponent,
    FleetGroupSelectorComponent,
  ],
  exports: [
    NavbarComponent,
    ImgModalComponent,
    DialogConfirmComponent,
    InputAutocompleteModularComponent,
    SelectAModularComponent,
    DatetimeCompleteComponent,
    NgxMaterialTimepickerModule,
    FleetGroupSelectorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MomentDateModule,
  ],
})
export class SharedModule {}
