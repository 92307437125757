<form [formGroup]="formControl" autocomplete="off">
  <div class="row">
    <div class="col">
      <mat-form-field class="w-100">
        <input matInput formControlName="plate">
        <mat-placeholder>Plate</mat-placeholder>
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>Flota</mat-label>
        <mat-select formControlName="fleet">
          <mat-option value="">Todas</mat-option>
          <mat-option *ngFor="let fleet of fleetList" [value]="fleet">
            {{ fleet }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col">
      <mat-form-field appearance="legacy" class="w-100">
        <mat-label>Grupo</mat-label>
        <mat-select formControlName="group">
          <mat-option value="">Todos</mat-option>
          <mat-option *ngFor="let group of groupList" [value]="group">
            {{ group }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>