import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(private _auth: AuthService) {}
  canLoad(): boolean {
    return this.authGuard();
  }
  canActivate(): boolean {
    return this.authGuard();
  }
  authGuard(): boolean {
    if (this._auth.itAuthenticated()) {
      return true;
    } else {
      this._auth.logout();
      return false;
    }
  }
}
