import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableUsersComponent } from '../../../dashboard/pages/users/components/table-users/table-users.component';

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  styles: [],
})
export class DialogConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TableUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; label: string }
  ) {}

  ngOnInit(): void {}
}
