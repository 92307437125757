export interface ITimezones {
  name: string;
  text: string;
}
export const timezones: ITimezones[] = [
  { name: 'Atlantic/Azores', text: 'UTC/GMT +00:00' },
  { name: 'America/Buenos_Aires', text: 'GMT-3 Argentina' },
  { name: 'America/La_Paz', text: 'GMT-4 Perú/Bolivia' },
  { name: 'America/Guayaquil', text: 'GMT-5 Bogotá' },
  { name: 'Pacific/Galapagos', text: 'GMT-6 Galapagos' },
];
